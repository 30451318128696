import React,{useState,useEffect} from "react";
import { useParams,NavLink,useNavigate } from 'react-router-dom';
// import { NavLink } from "react-router-dom"
import axios from 'axios'

function CompanyList(){
    const [data, setData] = useState([])
    const { id } = useParams()
    // const [inputCompany, setInputCompany] = useState({
    //     timecompany: "" ,
    //     companyid:data?.data?.map((elem)=>{
    //                 return elem._id
                   
    //           })
    // })
    const [time,setTime] = useState({
        time:"",
        id:""
    })

    const navigate = useNavigate()

    useEffect(()=>{
        
        loadUser()
    
},[])

const serverUrl = process.env.REACT_APP_API
const serverUrl1 = process.env.REACT_APP_SERVER
const loadUser=async ()=>{
    const result = await axios.get(`${serverUrl}/get-users/${id}`)
    console.log(">>>>>>result>.>>>>",result)
    if(result.status === 201){
        setData(result.data)
    }
     

}



const changeCompanyTime=(e)=>{
    setTime({time:e.target.value,
        id:e.target.id})
      console.log("e.target.value>>>>",{
        time:e.target.value,
        id:e.target.id,
    })
}

const companyHandler=(e)=>{
      e.preventDefault()
      let url = `${serverUrl1}/change_time_user`
      axios.post(url,time,{mode:"no-cors"}).then((response)=>{
            console.log(">.............>res",response)

      }).catch((err)=>{
         console.log(err.message)
      }) 
      
      console.log(">>>>>>time>",time)
}

const logoutHandler =(e)=>{
    e.preventDefault();
    localStorage.removeItem("login")
    navigate("/login")
      
   }

    return(
        <>
        <nav className="navbar bg-dark" data-bs-theme="dark">
                <div className="container-fluid">
                    <NavLink className="navbar-brand bg_das" to="/company_list/user_list" ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
</svg> Return To Dashboard </NavLink>
                    
                </div>
                <button type="submit" className='btn btn-primary' onClick={logoutHandler}>LogOut</button>
            </nav>
         <div className="user_table_outer table-outer">
            <div className="user_table_inner">
                <h3 className='heading' style={{ marginTop: "20px" }}>User List</h3><br />
                    <table className="table">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>User Name</th>
                                <th>Email</th>
                                <th>MacAddress</th>
                                <th>Set Interval</th>

                            </tr>
                        </thead>
                        <tbody>
                            
                        {data && data?.data?.map((item,index)=>{
                                    return(
                                        <tr key={index}>
                                            <th scope='row'>{index+1}</th>
                                            <td>{item.fullname}</td>
                                            <td>{item.email}</td> 
                                            <td>{item.macaddress}</td>
                                            <td><input type="" step='1' min="00:00:00" max="20:00:00" nam="timecompany" id={item._id} value={item.time} onChange={changeCompanyTime}/> <span><button type="submit" className='btn btn-primary' onClick={companyHandler} >send</button></span></td>
                                        </tr>
                                    )
                                    })}

                        </tbody>

                    </table>
                    </div>
                    </div>
        </>
    )
}
export default  CompanyList;